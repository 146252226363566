import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setToken } from 'services/user';

import { loginMessages, loginParams, storageParams } from './consts';

export const RedirectHandler = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get(loginParams.token);
	const message = searchParams.get(loginParams.message);
	const dispatch = useDispatch();

	if (message === loginMessages.success && token) {
		localStorage.setItem(storageParams.accessToken, token);
	}

	useEffect(() => {
		if (token) {
			dispatch(setToken(token));
			navigate('/quiz', { replace: true });
			return;
		}

		const urlParams = new URLSearchParams({
			message: message ?? 'unauthorized'
		});

		navigate(`/login?${urlParams.toString()}`);
	}, [message, navigate, token, dispatch]);

	return <></>;
};
