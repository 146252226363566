import { QuizQuestionType } from 'services/quiz';

export enum QuizQuestionCategory {
	custom = 'custom',
	age = 'age',
	email = 'email',
	phone_number = 'phone_number',
	birthday = 'birthday',
	bodyType = 'bodyType',
	clothing_categories = 'clothing_categories',
	clothing_preferences = 'clothing_preferences',
	colors = 'colors',
	event_type = 'event_type',
	fabrics = 'fabrics',
	gender = 'gender',
	goals = 'goals',
	heels = 'heels',
	highlight = 'highlight',
	holiday_type = 'holiday_type',
	location = 'location',
	photo = 'photo',
	clothing_size_scale = 'clothing_size_scale',
	shoes_size_scale = 'shoes_size_scale',
	sizes = 'sizes',
	budget = 'budget',
	style = 'style', // Parent category, shouldn't be sent to server
	what_matters = 'what_matters',
	work_type = 'work_type',
	brands = 'brands',
	fit_bottom = 'fit_bottom',
	fit_top = 'fit_top',
	// Style sub categories
	style_androgynous = 'style_androgynous',
	style_arty = 'style_arty',
	style_avant_garde = 'style_avant-garde',
	style_boho = 'style_boho',
	style_chic = 'style_chic',
	style_classic = 'style_classic',
	style_eclectic = 'style_eclectic',
	style_edgy = 'style_edgy',
	style_elegant = 'style_elegant',
	style_feminine = 'style_feminine',
	style_hipster = 'style_hipster',
	style_minimal = 'style_minimal',
	style_rock = 'style_rock',
	style_rugged = 'style_rugged',
	style_sexy = 'style_sexy',
	style_sporty = 'style_sporty',
	style_streetstyle = 'style_streetstyle',
	style_streetwear = 'style_streetwear',
	style_tailored = 'style_tailored',
	style_tomboy = 'style_tomboy',
	style_trendy = 'style_trendy',
	style_cult = 'style_cult',
	style_glamour = 'style_glamour',
	style_expressionist = 'style_expressionist',
	referral_source = 'referral_source',
	referral_source_instagram = 'referral_source_instagram',
	referral_source_stylist = 'referral_source_stylist',
	referral_source_friend = 'referral_source_friend',
	body_issues = 'body_issues',
	comfort_zone = 'comfort_zone',
	jewelry = 'jewelry',
	style_icons = 'style_icons',
	instagram = 'instagram',
	pinterest = 'pinterest',
	jeans_type = 'jeans_type',
	heels_type = 'heels_type',
	colors_to_avoid = 'colors_to_avoid',
	necklines_to_avoid = 'necklines_to_avoid',
	patterns_to_avoid = 'patterns_to_avoid',
	occupation = 'occupation',
}

export interface CategoryOptionInfo {
	key: string;
	name: string;
}

export interface QuestionCategoryInfo {
	key: QuizQuestionCategory;
	name: string;
	optionKeys?: CategoryOptionInfo[];
	allowedTypes?: Set<QuizQuestionType>;

	subCategories?: QuizQuestionCategory[];
	parentCategory?: QuizQuestionCategory;
}

const styleCategoryOptions = [
	{ key: 'always', name: 'Always' },
	{ key: 'sometimes', name: 'Sometimes' },
	{ key: 'never', name: 'Never' }
];
const styleCategoryAllowedTypes = new Set([
	QuizQuestionType.SelectOneButtons,
	QuizQuestionType.SelectOneRadio,
	QuizQuestionType.SelectOneTags
]);

export const CategoriesDict: Record<QuizQuestionCategory, QuestionCategoryInfo> = {
	[QuizQuestionCategory.custom]: {
		key: QuizQuestionCategory.gender,
		name: 'Custom'
	},
	[QuizQuestionCategory.gender]: {
		key: QuizQuestionCategory.gender,
		name: 'Gender',
		optionKeys: [
			{ key: 'female', name: 'Female' },
			{ key: 'male', name: 'Male' },
			{ key: 'neutral', name: 'Neutral' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.email]: {
		key: QuizQuestionCategory.email,
		name: 'Email',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
	[QuizQuestionCategory.phone_number]: {
		key: QuizQuestionCategory.phone_number,
		name: 'Phone Number',
		allowedTypes: new Set([QuizQuestionType.FreeText, QuizQuestionType.PhoneNumber])
	},
	[QuizQuestionCategory.birthday]: {
		key: QuizQuestionCategory.birthday,
		name: 'Birthday',
		allowedTypes: new Set([QuizQuestionType.FreeText, QuizQuestionType.Date])
	},
	[QuizQuestionCategory.bodyType]: {
		key: QuizQuestionCategory.bodyType,
		name: 'Body Type',
		optionKeys: [
			{ key: 'BODY_TYPE_SHORT', name: 'Short' },
			{ key: 'BODY_TYPE_AVERAGE', name: 'Average Height' },
			{ key: 'BODY_TYPE_TALL', name: 'Tall' },
			{ key: 'BODY_TYPE_CURVY', name: 'Curvy' },
			{ key: 'BODY_TYPE_FIT', name: 'Fit' },
			{ key: 'BODY_TYPE_LONG_AND_LEAN', name: 'Long and Lean' },
			{ key: 'BODY_TYPE_PETITE', name: 'Petite' },
			{ key: 'BODY_TYPE_PLUS_SIZE', name: 'Plus size' },
			{ key: 'BODY_TYPE_PREGNANT', name: 'Expecting' },
			{ key: 'BODY_TYPE_SLIM', name: 'Slim' }
		],
		allowedTypes: new Set([QuizQuestionType.SelectManyTags])
	},
	[QuizQuestionCategory.goals]: {
		key: QuizQuestionCategory.goals,
		name: 'Goals',
		optionKeys: [
			{ key: 'GOAL_BLACK_TIE', name: 'Black tie' },
			{ key: 'GOAL_CASUAL', name: 'Casual' },
			{ key: 'GOAL_CLOSET_CLEANOUT', name: 'Closet Cleanout' },
			{ key: 'GOAL_DATE_NIGHT', name: 'Date Night' },
			{ key: 'GOAL_EVENT', name: 'Event' },
			{ key: 'GOAL_FALL_CAPSULE', name: 'Fall Capsule' },
			{ key: 'GOAL_GIFT', name: 'Gift' },
			{ key: 'GOAL_HOLIDAY', name: 'Holiday' },
			{ key: 'GOAL_HOLIDAY_PARTY', name: 'Holiday Party' },
			{ key: 'GOAL_NEW_SEASON', name: 'New Season' },
			{ key: 'GOAL_PARTY', name: 'Party' },
			{ key: 'GOAL_RESORTWEAR', name: 'Resortwear' },
			{ key: 'GOAL_SPECIFIC_PIECE', name: 'Specific Piece' },
			{ key: 'GOAL_SUMMER_CAPSULE', name: 'Summer Capsule' },
			{ key: 'GOAL_STYLE_REFRESH', name: 'A Style Refresh' },
			{ key: 'GOAL_TRAVEL', name: 'Travel' },
			{ key: 'GOAL_WEDDING', name: 'Wedding' },
			{ key: 'GOAL_WEDDING_GUEST', name: 'Wedding Guest' },
			{ key: 'GOAL_WINTER_CAPSULE', name: 'Winter Capsule' },
			{ key: 'GOAL_WORKWEAR', name: 'Workwear' },
			{ key: 'GOAL_OTHER', name: 'Other' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneTags,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.work_type]: {
		key: QuizQuestionCategory.work_type,
		name: 'Work type',
		optionKeys: [
			{ key: 'WORK_ANYTHING', name: 'Anything' },
			{ key: 'WORK_BUSINESS_CASUAL', name: 'Business casual' },
			{ key: 'WORK_BUSINESS_FORMAL', name: 'Business formal' },
			{ key: 'WORK_BUSINESS_PROFESSIONAL', name: 'Business professional' },
			{ key: 'WORK_CASUAL', name: 'Casual' },
			{ key: 'WORK_CONSERVATIVE', name: 'Conservative' },
			{ key: 'WORK_CREATIVE', name: 'Creative' },
			{ key: 'WORK_DENIM', name: 'Denim' },
			{ key: 'WORK_SMART_CASUAL', name: 'Smart casual' },
			{ key: 'WORK_OTHER', name: 'Other' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.holiday_type]: {
		key: QuizQuestionCategory.holiday_type,
		name: 'Holiday type',
		optionKeys: [
			{ key: 'HOLIDAY_ACTIVE', name: 'Active' },
			{ key: 'HOLIDAY_BEACH', name: 'Beach' },
			{ key: 'HOLIDAY_CITY', name: 'City' },
			{ key: 'HOLIDAY_SKI', name: 'Ski' },
			{ key: 'HOLIDAY_SPA', name: 'Spa' },
			{ key: 'HOLIDAY_OTHER', name: 'Other' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.event_type]: {
		key: QuizQuestionCategory.event_type,
		name: 'Event type',
		optionKeys: [
			{ key: 'EVENT_BLACK_TIE', name: 'Black tie' },
			{ key: 'EVENT_CASUAL', name: 'Casual' },
			{ key: 'EVENT_COCKTAIL', name: 'Cocktail' },
			{ key: 'EVENT_DINNER', name: 'Dinner' },
			{ key: 'EVENT_INFORMAL', name: 'Informal' },
			{ key: 'EVENT_NIGHT', name: 'Night' },
			{ key: 'EVENT_WEDDING', name: 'Wedding' },
			{ key: 'EVENT_WORK', name: 'Work' },
			{ key: 'EVENT_OTHER', name: 'Other' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.colors]: {
		key: QuizQuestionCategory.colors,
		name: 'Colors',
		optionKeys: [
			{ key: 'COLOR_ALL', name: 'All' },
			{ key: 'PRINTS_ANIMAL', name: 'Animal' },
			{ key: 'COLOR_BISQUE', name: 'Bisque' },
			{ key: 'COLOR_BLACK', name: 'Black' },
			{ key: 'COLOR_BLUE', name: 'Blue' },
			{ key: 'COLOR_BROWN', name: 'Brown' },
			{ key: 'PRINTS_CAMO', name: 'Camo' },
			{ key: 'COLOR_DARK_BLUE', name: 'Dark blue' },
			{ key: 'PRINTS_FLORAL', name: 'Floral' },
			{ key: 'COLOR_FUCHSIA', name: 'Fuchsia' },
			{ key: 'COLOR_GOLD', name: 'Gold' },
			{ key: 'COLOR_GRAY', name: 'Gray' },
			{ key: 'COLOR_GREEN', name: 'Green' },
			{ key: 'COLOR_LIGHT_BLUE', name: 'Light blue' },
			{ key: 'COLOR_LIGHT_GRAY', name: 'Light gray' },
			{ key: 'COLOR_LINEN', name: 'Lined' },
			{ key: 'COLOR_MELON', name: 'Melon' },
			{ key: 'COLOR_MULTICOLOUR', name: 'Multicolor' },
			{ key: 'COLOR_OLIVE', name: 'Olive' },
			{ key: 'COLOR_ORANGE', name: 'Orange' },
			{ key: 'PRINTS_PAISLEY', name: 'Paisley' },
			{ key: 'PRINTS_PLAID', name: 'Plaid' },
			{ key: 'PRINTS_POLKA_DOTS', name: 'Polka dots' },
			{ key: 'COLOR_PURPLE', name: 'Purple' },
			{ key: 'COLOR_RED', name: 'Red' },
			{ key: 'COLOR_SILVER', name: 'Silver' },
			{ key: 'COLOR_STRIPED', name: 'Striped' },
			{ key: 'COLOR_TIE_DYE', name: 'Tie dye' },
			{ key: 'COLOR_WHITE', name: 'White' },
			{ key: 'COLOR_YELLOW', name: 'Yellow' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.colors_to_avoid]: {
		key: QuizQuestionCategory.colors_to_avoid,
		name: 'Colors to avoid',
		optionKeys: [
			{ key: 'COLORS_TO_AVOID_BEIGES', name: 'Beiges' },
			{ key: 'COLORS_TO_AVOID_BLACKS', name: 'Blacks' },
			{ key: 'COLORS_TO_AVOID_BLUES', name: 'Blues' },
			{ key: 'COLORS_TO_AVOID_BROWNS', name: 'Browns' },
			{ key: 'COLORS_TO_AVOID_GRAYS', name: 'Grays' },
			{ key: 'COLORS_TO_AVOID_GREENS', name: 'Greens' },
			{ key: 'COLORS_TO_AVOID_METALLIC', name: 'Metallic' },
			{ key: 'COLORS_TO_AVOID_ORANGES', name: 'Oranges' },
			{ key: 'COLORS_TO_AVOID_PINKS', name: 'Pinks' },
			{ key: 'COLORS_TO_AVOID_PURPLES', name: 'Purples' },
			{ key: 'COLORS_TO_AVOID_REDS', name: 'Reds' },
			{ key: 'COLORS_TO_AVOID_WHITES', name: 'Whites' },
			{ key: 'COLORS_TO_AVOID_YELLOWS', name: 'Yellows' },
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.fabrics]: {
		key: QuizQuestionCategory.fabrics,
		name: 'Fabrics to avoid',
		optionKeys: [
			{ key: 'FABRICS_ANIMAL', name: 'Animal' },
			{ key: 'FABRICS_DRY_CLEAN_ONLY', name: 'Dry clean only' },
			{ key: 'FABRICS_FAUX_FUR', name: 'Faux fur' },
			{ key: 'FABRICS_FUR', name: 'Fur' },
			{ key: 'FABRICS_LACE', name: 'Lace' },
			{ key: 'FABRICS_LEATHER', name: 'Leather' },
			{ key: 'FABRICS_PLEATHER', name: 'Pleather' },
			{ key: 'FABRICS_POLYESTER', name: 'Polyester' },
			{ key: 'FABRICS_SNAKESKIN', name: 'Snakeskin' },
			{ key: 'FABRICS_VELVET', name: 'Velvet' },
			{ key: 'FABRICS_WOOL', name: 'Wool' },
			{ key: 'FABRICS_LINEN', name: 'Linen' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.necklines_to_avoid]: {
		key: QuizQuestionCategory.necklines_to_avoid,
		name: 'Necklines to avoid',
		optionKeys: [
			{ key: 'NECKLINES_TO_AVOID_V', name: 'V neck' },
			{ key: 'NECKLINES_TO_AVOID_HALTER', name: 'Halter neck' },
			{ key: 'NECKLINES_TO_AVOID_TURTLE', name: 'Turtle neck' },
			{ key: 'NECKLINES_TO_AVOID_DEEP_V', name: 'Deep V' },
			{ key: 'NECKLINES_TO_AVOID_ROUND', name: 'Round neck' },
			{ key: 'NECKLINES_TO_AVOID_STRAPLESS', name: 'Strapless' },
			{ key: 'NECKLINES_TO_AVOID_SLEEVELESS', name: 'Sleeveless' },
			{ key: 'NECKLINES_TO_AVOID_BOAT', name: 'Boat neck' },
			{ key: 'NECKLINES_TO_AVOID_COWL', name: 'Cowl neck' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.patterns_to_avoid]: {
		key: QuizQuestionCategory.patterns_to_avoid,
		name: 'Patterns to avoid',
		optionKeys: [
			{ key: 'PATTERNS_TO_AVOID_ANIMAL', name: 'Animal Print' },
			{ key: 'PATTERNS_TO_AVOID_PAISLEY', name: 'Paisley' },
			{ key: 'PATTERNS_TO_AVOID_CAMO', name: 'Camo' },
			{ key: 'PATTERNS_TO_AVOID_PLAID', name: 'Plaid' },
			{ key: 'PATTERNS_TO_AVOID_POLKA_DOTS', name: 'Polka Dots' },
			{ key: 'PATTERNS_TO_AVOID_STRIPES', name: 'Stripes' },
			{ key: 'PATTERNS_TO_AVOID_FLORAL', name: 'Floral' },
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.clothing_categories]: {
		key: QuizQuestionCategory.clothing_categories,
		name: 'Clothing cateogires',
		optionKeys: [
			{ key: 'CLOTHING_CATEGORY_ACCESSORIES', name: 'Accessories' },
			{ key: 'CLOTHING_CATEGORY_ANYTHING', name: 'Anything' },
			{ key: 'CLOTHING_CATEGORY_BAGS', name: 'Bags' },
			{ key: 'CLOTHING_CATEGORY_BEACHWEAR', name: 'Beachwear' },
			{ key: 'CLOTHING_CATEGORY_BIKINI_BOTTOMS', name: 'Bikini Bottoms' },
			{ key: 'CLOTHING_CATEGORY_BIKINI_TOPS', name: 'Bikini Tops' },
			{ key: 'CLOTHING_CATEGORY_BLAZERS', name: 'Blazers' },
			{ key: 'CLOTHING_CATEGORY_COATS', name: 'Coats' },
			{ key: 'CLOTHING_CATEGORY_COVERUPS', name: 'Coverups' },
			{ key: 'CLOTHING_CATEGORY_DRESSES', name: 'Dresses' },
			{ key: 'CLOTHING_CATEGORY_HATS', name: 'Hats' },
			{ key: 'CLOTHING_CATEGORY_JACKETS', name: 'Jackets' },
			{ key: 'CLOTHING_CATEGORY_JEANS', name: 'Jeans' },
			{ key: 'CLOTHING_CATEGORY_JEWELRIES', name: 'Jewelries' },
			{ key: 'CLOTHING_CATEGORY_JUMPSUIT', name: 'Jumpsuit' },
			{ key: 'CLOTHING_CATEGORY_KNITWEAR', name: 'Knitwear' },
			{ key: 'CLOTHING_CATEGORY_OUTERWEAR', name: 'Outerwear' },
			{ key: 'CLOTHING_CATEGORY_ONE_PIECES', name: 'One Pieces' },
			{ key: 'CLOTHING_CATEGORY_PANTS', name: 'Pants' },
			{ key: 'CLOTHING_CATEGORY_SCARVES', name: 'Scarves' },
			{ key: 'CLOTHING_CATEGORY_SHIRTS', name: 'Shirts' },
			{ key: 'CLOTHING_CATEGORY_SHOES', name: 'Shoes' },
			{ key: 'CLOTHING_CATEGORY_SHORTS', name: 'Shorts' },
			{ key: 'CLOTHING_CATEGORY_SKIRTS', name: 'Skirts' },
			{ key: 'CLOTHING_CATEGORY_SUNGLASSES', name: 'Sunglasses' },
			{ key: 'CLOTHING_CATEGORY_SUITS', name: 'Suits' },
			// { key: 'CLOTHING_CATEGORY_SWEATER', name: 'Sweater' },
			{ key: 'CLOTHING_CATEGORY_SWEATERS', name: 'Sweaters' },
			{ key: 'CLOTHING_CATEGORY_TOPS', name: 'Tops' },
			{ key: 'CLOTHING_CATEGORY_OTHER', name: 'Other' }
			// { key: 'CLOTHING_CATEGORY_JUMPSUITS', name: 'Jumpsuits' },
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags,
			QuizQuestionType.SelectManyButtons
		])
	},
	[QuizQuestionCategory.clothing_preferences]: {
		key: QuizQuestionCategory.clothing_preferences,
		name: 'Clothing preferences',
		optionKeys: [
			{
				key: 'CLOTHING_PREFERENCES_DRESSES_AND_SKIRTS',
				name: 'Dresses and skirts'
			},
			{ key: 'CLOTHING_PREFERENCES_PANTS', name: 'Pants' },
			{ key: 'CLOTHING_PREFERENCES_MIX', name: 'Mix' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.heels]: {
		key: QuizQuestionCategory.heels,
		name: 'Heels',
		optionKeys: [
			{ key: 'HEELS_ALWAYS', name: 'Always' },
			{ key: 'HEELS_SOMETIMES', name: 'Sometimes' },
			{ key: 'HEELS_NEVER', name: 'Never' },
			{ key: 'HEELS_EVENTS', name: 'Events' },
			{ key: 'HEELS_WORK', name: 'Work' },
			{ key: 'HEELS_MIX', name: 'Mix' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.highlight]: {
		key: QuizQuestionCategory.highlight,
		name: 'Highlight',
		optionKeys: [
			{ key: 'HIGHLIGHT_ANKLES', name: 'Ankles' },
			{ key: 'HIGHLIGHT_ARMS', name: 'Arms' },
			{ key: 'HIGHLIGHT_ABS', name: 'Abs' },
			{ key: 'HIGHLIGHT_BACK', name: 'Back' },
			{ key: 'HIGHLIGHT_CALVES', name: 'Calves' },
			{ key: 'HIGHLIGHT_CLEAVAGE', name: 'Cleavage' },
			{ key: 'HIGHLIGHT_LEGS', name: 'Legs' },
			{ key: 'HIGHLIGHT_MIDRIFF', name: 'Midriff' },
			{ key: 'HIGHLIGHT_NECK', name: 'Neck' },
			{ key: 'HIGHLIGHT_REAR', name: 'Rear' },
			{ key: 'HIGHLIGHT_SHOULDERS', name: 'Shoulders' },
			{ key: 'HIGHLIGHT_WAIST', name: 'Waist' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		])
	},
	[QuizQuestionCategory.what_matters]: {
		key: QuizQuestionCategory.what_matters,
		name: 'What matters',
		optionKeys: [
			{ key: 'WHAT_MATTERS_COMFORT', name: 'Comfort' },
			{ key: 'WHAT_MATTERS_NEWNESS', name: 'Newness' },
			{ key: 'WHAT_MATTERS_SUSTAINABILITY', name: 'Sustainability' },
			{ key: 'WHAT_MATTERS_UNIQENESS', name: 'Uniqueness' },
			{ key: 'WHAT_MATTERS_VERSATILITY', name: 'Versatility' },
			{ key: 'WHAT_MATTERS_PREMIUM_MATERIALS', name: 'Premium Materials' },
			{ key: 'WHAT_MATTERS_QUALITY', name: 'Quality' },
			{ key: 'WHAT_MATTERS_LATEST_TRENDS', name: 'The latest trends' },
			{ key: 'WHAT_MATTERS_NEW_SEASON', name: 'New Season' },
			{ key: 'WHAT_MATTERS_MODESTY', name: 'Modesty' },
			{ key: 'WHAT_MATTERS_VEGAN', name: 'Vegan' },
			{ key: 'WHAT_MATTERS_QUIET_LUXURY', name: 'Quiet Luxury' },
			{ key: 'WHAT_MATTERS_MATCHING_SETS', name: 'Matching Sets' },
			{ key: 'WHAT_MATTERS_SURPRISE_ME', name: 'Surprise Me' },
			{ key: 'WHAT_MATTERS_EVENT_TYPE', name: 'Event Type' },
			{ key: 'WHAT_MATTERS_SOMETHING_ELSE', name: 'Something else' }
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		])
	},
	[QuizQuestionCategory.age]: {
		key: QuizQuestionCategory.age,
		name: 'Age',
		allowedTypes: new Set([QuizQuestionType.FreeNumber])
	},
	[QuizQuestionCategory.location]: {
		key: QuizQuestionCategory.location,
		name: 'Location',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
	[QuizQuestionCategory.photo]: {
		key: QuizQuestionCategory.photo,
		name: 'Photo',
		allowedTypes: new Set([QuizQuestionType.UploadPhoto])
	},
	[QuizQuestionCategory.clothing_size_scale]: {
		key: QuizQuestionCategory.clothing_size_scale,
		name: 'Clothing size scale',
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		]),
		optionKeys: [
			{ key: 'Standard', name: 'Standard' },
			{ key: 'US', name: 'US' },
			{ key: 'UK', name: 'UK' }
		]
	},
	[QuizQuestionCategory.shoes_size_scale]: {
		key: QuizQuestionCategory.shoes_size_scale,
		name: 'Shoes size scale',
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		]),
		optionKeys: [
			{ key: 'IT', name: 'IT' },
			{ key: 'US', name: 'US' },
			{ key: 'UK', name: 'UK' }
		]
	},
	[QuizQuestionCategory.sizes]: {
		key: QuizQuestionCategory.sizes,
		name: 'Sizes',
		allowedTypes: new Set([QuizQuestionType.DropDowns]),
		optionKeys: [
			{ key: 'SIZE_TOPS_M_3XS', name: 'Top M, 3XS' },
			{ key: 'SIZE_TOPS_M_XXS', name: 'Top M, XXS' },
			{ key: 'SIZE_TOPS_M_XS', name: 'Top M, XS' },
			{ key: 'SIZE_TOPS_M_S', name: 'Top M, Small' },
			{ key: 'SIZE_TOPS_M_M', name: 'Top M, Medium' },
			{ key: 'SIZE_TOPS_M_L', name: 'Top M, Large' },
			{ key: 'SIZE_TOPS_M_XL', name: 'Top M, XL' },
			{ key: 'SIZE_TOPS_M_XXL', name: 'Top M, XXL' },
			{ key: 'SIZE_TOPS_M_3XL', name: 'Top M, 3XL' },
			{ key: 'SIZE_TOPS_M_4XL', name: 'Top M, 4XL' },
			{ key: 'SIZE_TOPS_M_5XL', name: 'Top M, 5XL' },
			{ key: 'SIZE_TOPS_M_6XL', name: 'Top M, 6XL' },
			{ key: 'SIZE_TOPS_M_7XL', name: 'Top M, 7XL' },
			{ key: 'SIZE_TOPS_M_8XL', name: 'Top M, 8XL' },
			{ key: 'SIZE_TOPS_F_3XS', name: 'Top F, 3XS' },
			{ key: 'SIZE_TOPS_F_XXS', name: 'Top F, XXS' },
			{ key: 'SIZE_TOPS_F_XS', name: 'Top F, XS' },
			{ key: 'SIZE_TOPS_F_S', name: 'Top F, Small' },
			{ key: 'SIZE_TOPS_F_M', name: 'Top F, Medium' },
			{ key: 'SIZE_TOPS_F_L', name: 'Top F, Large' },
			{ key: 'SIZE_TOPS_F_XL', name: 'Top F, XL' },
			{ key: 'SIZE_TOPS_F_XXL', name: 'Top F, XXL' },
			{ key: 'SIZE_TOPS_F_3XL', name: 'Top F, 3XL' },
			{ key: 'SIZE_TOPS_F_4XL', name: 'Top F, 4XL' },
			{ key: 'SIZE_TOPS_F_5XL', name: 'Top F, 5XL' },
			{ key: 'SIZE_TOPS_F_6XL', name: 'Top F, 6XL' },
			{ key: 'SIZE_TOPS_F_7XL', name: 'Top F, 7XL' },
			{ key: 'SIZE_BOTTOM_M_3XS', name: 'Bottom M, 3XS' },
			{ key: 'SIZE_BOTTOM_M_XXS', name: 'Bottom M, XXS' },
			{ key: 'SIZE_BOTTOM_M_XS', name: 'Bottom M, XS' },
			{ key: 'SIZE_BOTTOM_M_S', name: 'Bottom M, Small' },
			{ key: 'SIZE_BOTTOM_M_M', name: 'Bottom M, Medium' },
			{ key: 'SIZE_BOTTOM_M_L', name: 'Bottom M, Large' },
			{ key: 'SIZE_BOTTOM_M_XL', name: 'Bottom M, XL' },
			{ key: 'SIZE_BOTTOM_M_XXL', name: 'Bottom M, XXL' },
			{ key: 'SIZE_BOTTOM_M_3XL', name: 'Bottom M, 3XL' },
			{ key: 'SIZE_BOTTOM_M_4XL', name: 'Bottom M, 4XL' },
			{ key: 'SIZE_BOTTOM_M_5XL', name: 'Bottom M, 5XL' },
			{ key: 'SIZE_BOTTOM_M_6XL', name: 'Bottom M, 6XL' },
			{ key: 'SIZE_BOTTOM_M_7XL', name: 'Bottom M, 7XL' },
			{ key: 'SIZE_BOTTOM_M_8XL', name: 'Bottom M, 8XL' },
			{ key: 'SIZE_BOTTOM_F_3XS', name: 'Bottom F, 3XS' },
			{ key: 'SIZE_BOTTOM_F_XXS', name: 'Bottom F, XXS' },
			{ key: 'SIZE_BOTTOM_F_XS', name: 'Bottom F, XS' },
			{ key: 'SIZE_BOTTOM_F_S', name: 'Bottom F, Small' },
			{ key: 'SIZE_BOTTOM_F_M', name: 'Bottom F, Medium' },
			{ key: 'SIZE_BOTTOM_F_L', name: 'Bottom F, Large' },
			{ key: 'SIZE_BOTTOM_F_XL', name: 'Bottom F, XL' },
			{ key: 'SIZE_BOTTOM_F_XXL', name: 'Bottom F, XXL' },
			{ key: 'SIZE_BOTTOM_F_3XL', name: 'Bottom F, 3XL' },
			{ key: 'SIZE_BOTTOM_F_4XL', name: 'Bottom F, 4XL' },
			{ key: 'SIZE_BOTTOM_F_5XL', name: 'Bottom F, 5XL' },
			{ key: 'SIZE_BOTTOM_F_6XL', name: 'Bottom F, 6XL' },
			{ key: 'SIZE_BOTTOM_F_7XL', name: 'Bottom F, 7XL' },
			{ key: 'JEANS_22', name: 'Jeans 22' },
			{ key: 'JEANS_23', name: 'Jeans 23' },
			{ key: 'JEANS_24', name: 'Jeans 24' },
			{ key: 'JEANS_25', name: 'Jeans 25' },
			{ key: 'JEANS_26', name: 'Jeans 26' },
			{ key: 'JEANS_27', name: 'Jeans 27' },
			{ key: 'JEANS_28', name: 'Jeans 28' },
			{ key: 'JEANS_29', name: 'Jeans 29' },
			{ key: 'JEANS_30', name: 'Jeans 30' },
			{ key: 'JEANS_31', name: 'Jeans 31' },
			{ key: 'JEANS_32', name: 'Jeans 32' },
			{ key: 'JEANS_33', name: 'Jeans 33' },
			{ key: 'JEANS_34', name: 'Jeans 34' },
			{ key: 'JEANS_35', name: 'Jeans 35' },
			{ key: 'JEANS_36', name: 'Jeans 36' },
			{ key: 'JEANS_37', name: 'Jeans 37' },
			{ key: 'JEANS_38', name: 'Jeans 38' },
			{ key: 'JEANS_39', name: 'Jeans 39' },
			{ key: 'JEANS_40', name: 'Jeans 40' },
			{ key: 'JEANS_41', name: 'Jeans 41' },
			{ key: 'JEANS_42', name: 'Jeans 42' },
			{ key: 'SIZE_SHOES_F_4', name: 'Shoes F, 4' },
			{ key: 'SIZE_SHOES_F_4_5', name: 'Shoes F, 4.5' },
			{ key: 'SIZE_SHOES_F_5', name: 'Shoes F, 5' },
			{ key: 'SIZE_SHOES_F_5_5', name: 'Shoes F, 5.5' },
			{ key: 'SIZE_SHOES_F_6', name: 'Shoes F, 6' },
			{ key: 'SIZE_SHOES_F_6_5', name: 'Shoes F, 6.5' },
			{ key: 'SIZE_SHOES_F_7', name: 'Shoes F, 7' },
			{ key: 'SIZE_SHOES_F_7_5', name: 'Shoes F, 7.5' },
			{ key: 'SIZE_SHOES_F_8', name: 'Shoes F, 8' },
			{ key: 'SIZE_SHOES_F_8_5', name: 'Shoes F, 8.5' },
			{ key: 'SIZE_SHOES_F_9', name: 'Shoes F, 9' },
			{ key: 'SIZE_SHOES_F_9_5', name: 'Shoes F, 9.5' },
			{ key: 'SIZE_SHOES_F_10', name: 'Shoes F, 10' },
			{ key: 'SIZE_SHOES_F_10_5', name: 'Shoes F, 10.5' },
			{ key: 'SIZE_SHOES_F_11', name: 'Shoes F, 11' },
			{ key: 'SIZE_SHOES_F_11_5', name: 'Shoes F, 11.5' },
			{ key: 'SIZE_SHOES_F_12', name: 'Shoes F, 12' },
			{ key: 'SIZE_SHOES_F_12_5', name: 'Shoes F, 12.5' },
			{ key: 'SIZE_SHOES_F_13', name: 'Shoes F, 13' },
			{ key: 'SIZE_SHOES_F_13_5', name: 'Shoes F, 13.5' },
			{ key: 'SIZE_SHOES_F_14', name: 'Shoes F, 14' },
			{ key: 'SIZE_SHOES_F_14_5', name: 'Shoes F, 14.5' },
			{ key: 'SIZE_SHOES_F_15', name: 'Shoes F, 15' },
			{ key: 'SIZE_SHOES_F_15_5', name: 'Shoes F, 15.5' },
			{ key: 'SIZE_SHOES_F_16', name: 'Shoes F, 16' },
			{ key: 'SIZE_SHOES_F_16_5', name: 'Shoes F, 16.5' },
			{ key: 'SIZE_SHOES_F_17', name: 'Shoes F, 17' },
			{ key: 'SIZE_SHOES_M_3', name: 'Shoes M, 3' },
			{ key: 'SIZE_SHOES_M_3_5', name: 'Shoes M, 3.5' },
			{ key: 'SIZE_SHOES_M_4', name: 'Shoes M, 4' },
			{ key: 'SIZE_SHOES_M_4_5', name: 'Shoes M, 4.5' },
			{ key: 'SIZE_SHOES_M_5', name: 'Shoes M, 5' },
			{ key: 'SIZE_SHOES_M_5_5', name: 'Shoes M, 5.5' },
			{ key: 'SIZE_SHOES_M_6', name: 'Shoes M, 6' },
			{ key: 'SIZE_SHOES_M_6_5', name: 'Shoes M, 6.5' },
			{ key: 'SIZE_SHOES_M_7', name: 'Shoes M, 7' },
			{ key: 'SIZE_SHOES_M_7_5', name: 'Shoes M, 7.5' },
			{ key: 'SIZE_SHOES_M_8', name: 'Shoes M, 8' },
			{ key: 'SIZE_SHOES_M_8_5', name: 'Shoes M, 8.5' },
			{ key: 'SIZE_SHOES_M_9', name: 'Shoes M, 9' },
			{ key: 'SIZE_SHOES_M_9_5', name: 'Shoes M, 9.5' },
			{ key: 'SIZE_SHOES_M_10', name: 'Shoes M, 10' },
			{ key: 'SIZE_SHOES_M_10_5', name: 'Shoes M, 10.5' },
			{ key: 'SIZE_SHOES_M_11', name: 'Shoes M, 11' },
			{ key: 'SIZE_SHOES_M_11_5', name: 'Shoes M, 11.5' },
			{ key: 'SIZE_SHOES_M_12', name: 'Shoes M, 12' },
			{ key: 'SIZE_SHOES_M_12_5', name: 'Shoes M, 12.5' },
			{ key: 'SIZE_SHOES_M_13', name: 'Shoes M, 13' },
			{ key: 'SIZE_SHOES_M_13_5', name: 'Shoes M, 13.5' },
			{ key: 'SIZE_SHOES_M_14', name: 'Shoes M, 14' },
			{ key: 'SIZE_SHOES_M_14_5', name: 'Shoes M, 14.5' },
			{ key: 'SIZE_SHOES_M_15', name: 'Shoes M, 15' },
			{ key: 'SIZE_SHOES_M_15_5', name: 'Shoes M, 15.5' },
			{ key: 'SIZE_SHOES_M_16', name: 'Shoes M, 16' },
			{ key: 'SIZE_SHOES_M_16_5', name: 'Shoes M, 16.5' },
			{ key: 'SIZE_SHOES_M_17', name: 'Shoes M, 17' },
			{ key: 'SIZE_DRESS_US_00', name: 'Dress XX-Small, 00' },
			{ key: 'SIZE_DRESS_US_0', name: 'Dress X-Small, 0' },
			{ key: 'SIZE_DRESS_US_2', name: 'Dress X-Small, 2' },
			{ key: 'SIZE_DRESS_US_4', name: 'Dress Small, 4' },
			{ key: 'SIZE_DRESS_US_6', name: 'Dress Small, 6' },
			{ key: 'SIZE_DRESS_US_8', name: 'Dress Medium, 8' },
			{ key: 'SIZE_DRESS_US_10', name: 'Dress Medium, 10' },
			{ key: 'SIZE_DRESS_US_12', name: 'Dress Large, 12' },
			{ key: 'SIZE_DRESS_US_14', name: 'Dress Large, 14' },
			{ key: 'SIZE_DRESS_US_16', name: 'Dress X-Large, 16' },
			{ key: 'SIZE_DRESS_US_18', name: 'Dress X-Large, 18' },
			{ key: 'SIZE_DRESS_US_20', name: 'Dress XX-Large, 20' },
			{ key: 'SIZE_DRESS_US_22', name: 'Dress XX-Large, 22' },
			{ key: 'SIZE_DRESS_US_24', name: 'Dress XXX-Large, 24' },
			{ key: 'SIZE_DRESS_US_26', name: 'Dress XXX-Large, 26' },
			{ key: 'SIZE_DRESS_US_28', name: 'Dress XXXX-Large, 28' },
			{ key: 'SIZE_OUTERWEAR_US_XXXS', name: 'Suits 3X-Small, 28' },
			{ key: 'SIZE_OUTERWEAR_US_XXS', name: 'Suits XX-Small, 30' },
			{ key: 'SIZE_OUTERWEAR_US_XS', name: 'Suits X-Small, 32' },
			{ key: 'SIZE_OUTERWEAR_US_S', name: 'Suits Small, 34-36' },
			{ key: 'SIZE_OUTERWEAR_US_M', name: 'Suits Medium, 38-40' },
			{ key: 'SIZE_OUTERWEAR_US_L', name: 'Suits Large, 42-44' },
			{ key: 'SIZE_OUTERWEAR_US_XL', name: 'Suits X-Large, 46' },
			{ key: 'SIZE_OUTERWEAR_US_XXL', name: 'Suits XX-Large, 48' },
			{ key: 'SIZE_OUTERWEAR_US_3XL', name: 'Suits 3X-Large, 50' },
			{ key: 'SIZE_OUTERWEAR_US_4XL', name: 'Suits 4X-Large, 52' },
			{ key: 'SIZE_OUTERWEAR_US_5XL', name: 'Suits 5X-Large, 54' },
			{ key: 'SIZE_OUTERWEAR_M_3XS', name: 'Suits M, 3XS' },
			{ key: 'SIZE_OUTERWEAR_M_XXS', name: 'Suits M, XXS' },
			{ key: 'SIZE_OUTERWEAR_M_XS', name: 'Suits M, XS' },
			{ key: 'SIZE_OUTERWEAR_M_S', name: 'Suits M, Small' },
			{ key: 'SIZE_OUTERWEAR_M_M', name: 'Suits M, Medium' },
			{ key: 'SIZE_OUTERWEAR_M_L', name: 'Suits M, Large' },
			{ key: 'SIZE_OUTERWEAR_M_XL', name: 'Suits M, XL' },
			{ key: 'SIZE_OUTERWEAR_M_XXL', name: 'Suits M, XXL' },
			{ key: 'SIZE_OUTERWEAR_M_3XL', name: 'Suits M, 3XL' },
			{ key: 'SIZE_OUTERWEAR_M_4XL', name: 'Suits M, 4XL' },
			{ key: 'SIZE_OUTERWEAR_M_5XL', name: 'Suits M, 5XL' },
			{ key: 'SIZE_OUTERWEAR_M_6XL', name: 'Suits M, 6XL' },
			{ key: 'SIZE_OUTERWEAR_M_7XL', name: 'Suits M, 7XL' },
			{ key: 'SIZE_OUTERWEAR_M_8XL', name: 'Suits M, 8XL' },
			{ key: 'SIZE_OUTERWEAR_F_3XS', name: 'Suits F, 3XS' },
			{ key: 'SIZE_OUTERWEAR_F_XXS', name: 'Suits F, XXS' },
			{ key: 'SIZE_OUTERWEAR_F_XS', name: 'Suits F, XS' },
			{ key: 'SIZE_OUTERWEAR_F_S', name: 'Suits F, Small' },
			{ key: 'SIZE_OUTERWEAR_F_M', name: 'Suits F, Medium' },
			{ key: 'SIZE_OUTERWEAR_F_L', name: 'Suits F, Large' },
			{ key: 'SIZE_OUTERWEAR_F_XL', name: 'Suits F, XL' },
			{ key: 'SIZE_OUTERWEAR_F_XXL', name: 'Suits F, XXL' },
			{ key: 'SIZE_OUTERWEAR_F_3XL', name: 'Suits F, 3XL' },
			{ key: 'SIZE_OUTERWEAR_F_4XL', name: 'Suits F, 4XL' },
			{ key: 'SIZE_OUTERWEAR_F_5XL', name: 'Suits F, 5XL' },
			{ key: 'SIZE_OUTERWEAR_F_6XL', name: 'Suits F, 6XL' },
			{ key: 'SIZE_OUTERWEAR_F_7XL', name: 'Suits F, 7XL' }
		]
	},
	[QuizQuestionCategory.budget]: {
		key: QuizQuestionCategory.budget,
		name: 'Budget',
		allowedTypes: new Set([
			QuizQuestionType.DropDowns,
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneTags,
			QuizQuestionType.SelectOneRadio
		]),
		optionKeys: [
			{ key: 'BUDGET_CLOTHING_0_200', name: 'Clothing Under $200' },
			{ key: 'BUDGET_CLOTHING_0_300', name: 'Clothing Under $300' },
			{ key: 'BUDGET_CLOTHING_200_TO_400', name: 'Clothing $200 - $400' },
			{ key: 'BUDGET_CLOTHING_300_500', name: 'Clothing $300 - $500' },
			{ key: 'BUDGET_CLOTHING_400_PLUS', name: 'Clothing $400+' },
			{ key: 'BUDGET_CLOTHING_500_1000', name: 'Clothing $500 - $1,000' },
			{ key: 'BUDGET_CLOTHING_1000_PLUS', name: 'Clothing $1,000+' },
			{ key: 'BUDGET_BLOUSES_50_100', name: 'Tops $50 - $100' },
			{ key: 'BUDGET_BLOUSES_100_250', name: 'Tops $100 - $250' },
			{ key: 'BUDGET_BLOUSES_250_500', name: 'Tops $250 - $500' },
			{ key: 'BUDGET_BLOUSES_500_1000', name: 'Tops $500 - $1000' },
			{ key: 'BUDGET_BLOUSES_1000_PLUS', name: 'Tops $1000+' },
			{ key: 'BUDGET_OUTERWEAR_50_100', name: 'Outerwear $50 - $100' },
			{ key: 'BUDGET_OUTERWEAR_100_250', name: 'Outerwear $100 - $250' },
			{ key: 'BUDGET_OUTERWEAR_250_500', name: 'Outerwear $250 - $500' },
			{ key: 'BUDGET_OUTERWEAR_500_1000', name: 'Outerwear $500 - $1000' },
			{ key: 'BUDGET_OUTERWEAR_1000_PLUS', name: 'Outerwear $1000+' },
			{ key: 'BUDGET_DRESSES_50_100', name: 'Dresses $50 - $100' },
			{ key: 'BUDGET_DRESSES_100_250', name: 'Dresses $100 - $250' },
			{ key: 'BUDGET_DRESSES_250_500', name: 'Dresses $250 - $500' },
			{ key: 'BUDGET_DRESSES_500_1000', name: 'Dresses $500 - $1000' },
			{ key: 'BUDGET_DRESSES_1000_PLUS', name: 'Dresses $1000+' },
			{ key: 'BUDGET_BOTTOMS_50_100', name: 'Bottoms $50 - $100' },
			{ key: 'BUDGET_BOTTOMS_100_250', name: 'Bottoms $100 - $250' },
			{ key: 'BUDGET_BOTTOMS_250_500', name: 'Bottoms $250 - $500' },
			{ key: 'BUDGET_BOTTOMS_500_1000', name: 'Bottoms $500 - $1000' },
			{ key: 'BUDGET_BOTTOMS_1000_PLUS', name: 'Bottoms $1000+' },
			{ key: 'BUDGET_JEANS_50_100', name: 'Jeans $50 - $100' },
			{ key: 'BUDGET_JEANS_100_250', name: 'Jeans $100 - $250' },
			{ key: 'BUDGET_JEANS_250_500', name: 'Jeans $250 - $500' },
			{ key: 'BUDGET_JEANS_500_1000', name: 'Jeans $500 - $1000' },
			{ key: 'BUDGET_JEANS_1000_PLUS', name: 'Jeans $1000+' },
			{ key: 'BUDGET_SHOES_0_300', name: 'Shoes Under $300' },
			{ key: 'BUDGET_SHOES_300_500', name: 'Shoes $300 - $500' },
			{ key: 'BUDGET_SHOES_50_100', name: 'Shoes $50 - $100' },
			{ key: 'BUDGET_SHOES_100_250', name: 'Shoes $100 - $250' },
			{ key: 'BUDGET_SHOES_250_500', name: 'Shoes $250 - $500' },
			{ key: 'BUDGET_SHOES_500_1000', name: 'Shoes $500 - $1000' },
			{ key: 'BUDGET_SHOES_1000_PLUS', name: 'Shoes $1000+' },
			{ key: 'BUDGET_BAGS_0_300', name: 'Handbags Under $300' },
			{ key: 'BUDGET_BAGS_300_500', name: 'Handbags $300 - $500' },
			{ key: 'BUDGET_BAGS_50_100', name: 'Handbags $50 - $100' },
			{ key: 'BUDGET_BAGS_100_250', name: 'Handbags $100 - $250' },
			{ key: 'BUDGET_BAGS_250_500', name: 'Handbags $250 - $500' },
			{ key: 'BUDGET_BAGS_500_1000', name: 'Handbags $500 - $1000' },
			{ key: 'BUDGET_BAGS_1000_PLUS', name: 'Handbags $1000+' },
			{ key: 'BUDGET_JEWELRY_0_300', name: 'Jewelry Under $300' },
			{ key: 'BUDGET_JEWELRY_300_500', name: 'Jewelry $300 - $500' },
			{ key: 'BUDGET_JEWELRY_500_1000', name: 'Jewelry $500 - $1,000' },
			{ key: 'BUDGET_JEWELRY_1000_PLUS', name: 'Jewelry $1,000+' },
			{ key: 'BUDGET_ACCESSORIES_0_300', name: 'Accessories Under $300' },
			{ key: 'BUDGET_ACCESSORIES_300_500', name: 'Accessories $300 - $500' },
			{ key: 'BUDGET_ACCESSORIES_50_100', name: 'Accessories $50 - $100' },
			{ key: 'BUDGET_ACCESSORIES_100_250', name: 'Accessories $100 - $250' },
			{ key: 'BUDGET_ACCESSORIES_250_500', name: 'Accessories $250 - $500' },
			{ key: 'BUDGET_ACCESSORIES_500_1000', name: 'Accessories $500 - $1000' },
			{ key: 'BUDGET_ACCESSORIES_1000_PLUS', name: 'Accessories $1000+' }
		]
	},
	[QuizQuestionCategory.brands]: {
		key: QuizQuestionCategory.brands,
		name: 'Brands',
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		])
	},
	[QuizQuestionCategory.fit_bottom]: {
		key: QuizQuestionCategory.fit_bottom,
		name: 'Fit bottom',
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		]),
		optionKeys: [
			{ key: 'FIT_BOTTOM_FITTED', name: 'Fitted' },
			{ key: 'FIT_BOTTOM_LOOSE', name: 'Loose' },
			{ key: 'FIT_BOTTOM_OVERSIZED', name: 'Oversized' },
			{ key: 'FIT_BOTTOM_STRAIGHT', name: 'Straight' },
			{ key: 'FIT_BOTTOM_TIGHT', name: 'Tight' }
		]
	},
	[QuizQuestionCategory.fit_top]: {
		key: QuizQuestionCategory.fit_top,
		name: 'Fit top',
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		]),
		optionKeys: [
			{ key: 'FIT_TOP_FITTED', name: 'Fitted' },
			{ key: 'FIT_TOP_LOOSE', name: 'Loose' },
			{ key: 'FIT_TOP_OVERSIZED', name: 'Oversized' },
			{ key: 'FIT_TOP_STRAIGHT', name: 'Straight' },
			{ key: 'FIT_TOP_TIGHT', name: 'Tight' }
		]
	},
	[QuizQuestionCategory.style]: {
		key: QuizQuestionCategory.style,
		name: 'Style',
		subCategories: [
			QuizQuestionCategory.style_androgynous,
			QuizQuestionCategory.style_arty,
			QuizQuestionCategory.style_avant_garde,
			QuizQuestionCategory.style_boho,
			QuizQuestionCategory.style_chic,
			QuizQuestionCategory.style_classic,
			QuizQuestionCategory.style_cult,
			QuizQuestionCategory.style_eclectic,
			QuizQuestionCategory.style_edgy,
			QuizQuestionCategory.style_elegant,
			QuizQuestionCategory.style_expressionist,
			QuizQuestionCategory.style_feminine,
			QuizQuestionCategory.style_glamour,
			QuizQuestionCategory.style_hipster,
			QuizQuestionCategory.style_minimal,
			QuizQuestionCategory.style_rock,
			QuizQuestionCategory.style_rugged,
			QuizQuestionCategory.style_sexy,
			QuizQuestionCategory.style_sporty,
			QuizQuestionCategory.style_streetstyle,
			QuizQuestionCategory.style_streetwear,
			QuizQuestionCategory.style_tailored,
			QuizQuestionCategory.style_tomboy,
			QuizQuestionCategory.style_trendy
		]
	},
	[QuizQuestionCategory.style_minimal]: {
		key: QuizQuestionCategory.style_minimal,
		name: 'Minimal',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_avant_garde]: {
		key: QuizQuestionCategory.style_avant_garde,
		name: 'Avant Garde',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_feminine]: {
		key: QuizQuestionCategory.style_feminine,
		name: 'Feminine',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_classic]: {
		key: QuizQuestionCategory.style_classic,
		name: 'Classic',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_sporty]: {
		key: QuizQuestionCategory.style_sporty,
		name: 'Sporty',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_streetwear]: {
		key: QuizQuestionCategory.style_streetwear,
		name: 'Streetwear',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_tailored]: {
		key: QuizQuestionCategory.style_tailored,
		name: 'Tailored',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_androgynous]: {
		key: QuizQuestionCategory.style_androgynous,
		name: 'Androgynous',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_edgy]: {
		key: QuizQuestionCategory.style_edgy,
		name: 'Edgy',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_arty]: {
		key: QuizQuestionCategory.style_arty,
		name: 'Arty',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_chic]: {
		key: QuizQuestionCategory.style_chic,
		name: 'Chic',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_boho]: {
		key: QuizQuestionCategory.style_boho,
		name: 'Boho',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_rock]: {
		key: QuizQuestionCategory.style_rock,
		name: 'Rock',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_sexy]: {
		key: QuizQuestionCategory.style_sexy,
		name: 'Sexy',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_eclectic]: {
		key: QuizQuestionCategory.style_eclectic,
		name: 'Eclectic',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_tomboy]: {
		key: QuizQuestionCategory.style_tomboy,
		name: 'Tomboy',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_streetstyle]: {
		key: QuizQuestionCategory.style_streetstyle,
		name: 'Streetstyle',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_rugged]: {
		key: QuizQuestionCategory.style_rugged,
		name: 'Rugged',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_hipster]: {
		key: QuizQuestionCategory.style_hipster,
		name: 'Hipster',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_elegant]: {
		key: QuizQuestionCategory.style_elegant,
		name: 'Elegant',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_trendy]: {
		key: QuizQuestionCategory.style_trendy,
		name: 'Trendy',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_cult]: {
		key: QuizQuestionCategory.style_cult,
		name: 'Cult',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_glamour]: {
		key: QuizQuestionCategory.style_glamour,
		name: 'Glamour',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.style_expressionist]: {
		key: QuizQuestionCategory.style_expressionist,
		name: 'Expressionist',
		optionKeys: styleCategoryOptions,
		allowedTypes: styleCategoryAllowedTypes,
		parentCategory: QuizQuestionCategory.style
	},
	[QuizQuestionCategory.referral_source]: {
		key: QuizQuestionCategory.referral_source,
		name: 'Referral source',
		optionKeys: [
			{
				key: 'instagram',
				name: 'Instagram'
			},
			{
				key: 'stylist',
				name: 'Stylist'
			},
			{
				key: 'friend',
				name: 'Friend'
			},
			{
				key: 'media',
				name: 'Media'
			},
			{
				key: 'search',
				name: 'Search'
			},
			{
				key: 'pinterest',
				name: 'Pinterest'
			},
			{
				key: 'facebook',
				name: 'Facebook'
			},
			{
				key: 'email',
				name: 'Email'
			},
			{
				key: 'repeat_customer',
				name: 'Repeat customer'
			}
		],
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		])
	},
	[QuizQuestionCategory.referral_source_instagram]: {
		key: QuizQuestionCategory.referral_source_instagram,
		name: 'Referral source instagram',
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		]),
		optionKeys: [
			{ key: 'REFERRAL_SOURCE_INSTAGRAM_WISHI', name: 'Instagram Wishi' },
			{ key: 'REFERRAL_SOURCE_INSTAGRAM_OTHER', name: 'Instagram Other' }
		]
	},
	[QuizQuestionCategory.referral_source_stylist]: {
		key: QuizQuestionCategory.referral_source_stylist,
		name: 'Referral source stylist',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
	[QuizQuestionCategory.referral_source_friend]: {
		key: QuizQuestionCategory.referral_source_friend,
		name: 'Referral source friend',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
	[QuizQuestionCategory.body_issues]: {
		key: QuizQuestionCategory.body_issues,
		name: 'Body areas',
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		]),
		optionKeys: [
			{ key: 'BODY_ISSUES_ARMS', name: 'Arms' },
			{ key: 'BODY_ISSUES_STOMACH', name: 'Stomach' },
			{ key: 'BODY_ISSUES_REAR', name: 'Rear' },
			{ key: 'BODY_ISSUES_HIPS', name: 'Hips' },
			{ key: 'BODY_ISSUES_LEGS', name: 'Legs' },
			{ key: 'BODY_ISSUES_CHEST', name: 'Chest' },
			{ key: 'BODY_ISSUES_FOOT', name: 'Foot' },
			{ key: 'BODY_ISSUES_HEALTH_CONCERNS', name: 'Health concerns' },
			{ key: 'BODY_ISSUES_OTHER', name: 'Other' }
		]
	},
	[QuizQuestionCategory.comfort_zone]: {
		key: QuizQuestionCategory.comfort_zone,
		name: 'Comfort zone',
		allowedTypes: new Set([
			QuizQuestionType.SelectOneButtons,
			QuizQuestionType.SelectOneRadio,
			QuizQuestionType.SelectOneTags
		]),
		optionKeys: [
			{ key: 'COMFORT_ZONE_NOT_AT_ALL', name: 'Not open for change' },
			{ key: 'COMFORT_ZONE_OPEN_FOR_CHANGE', name: 'Open for change' },
			{ key: 'COMFORT_ZONE_TOTALLY', name: 'Totally open for change' }
		]
	},
	[QuizQuestionCategory.jewelry]: {
		key: QuizQuestionCategory.jewelry,
		name: 'Jewelry',
		allowedTypes: new Set([
			QuizQuestionType.SelectManyButtons,
			QuizQuestionType.SelectManyCheckbox,
			QuizQuestionType.SelectManyTags
		]),
		optionKeys: [
			{ key: 'JEWELRY_BLACK', name: 'Black' },
			{ key: 'JEWELRY_SILVER', name: 'Silver' },
			{ key: 'JEWELRY_ROSE_GOLD', name: 'Rose gold' },
			{ key: 'JEWELRY_COLORFUL', name: 'Colorful' },
			{ key: 'JEWELRY_VINTAGE', name: 'Vintage' },
			{ key: 'JEWELRY_GOLD', name: 'Gold' }
		]
	},
	[QuizQuestionCategory.style_icons]: {
		key: QuizQuestionCategory.style_icons,
		name: 'Style icons',
		allowedTypes: new Set([QuizQuestionType.SelectManyButtons])
	},
	[QuizQuestionCategory.instagram]: {
		key: QuizQuestionCategory.instagram,
		name: 'Instagram',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
	[QuizQuestionCategory.pinterest]: {
		key: QuizQuestionCategory.pinterest,
		name: 'Pinterest',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
	[QuizQuestionCategory.jeans_type]: {
		key: QuizQuestionCategory.jeans_type,
		name: 'Jeans type',
		allowedTypes: new Set([QuizQuestionType.SelectManyButtons, QuizQuestionType.SelectManyTags]),
		optionKeys: [
			{ key: 'JEANS_SKINNY', name: 'Skinny' },
			{ key: 'JEANS_STRAIGHT', name: 'Straight' },
			{ key: 'JEANS_BOYFRIEND', name: 'Boyfriend' },
			{ key: 'JEANS_DISTROYED', name: 'Destroyed' },
			{ key: 'JEANS_FLAIR', name: 'Flair' },
			{ key: 'JEANS_CROPPED', name: 'Cropped' },
			{ key: 'JEANS_HIGH_RISE', name: 'High rise' },
			{ key: 'JEANS_LOW_RISE', name: 'Low rise' }
		]
	},
	[QuizQuestionCategory.heels_type]: {
		key: QuizQuestionCategory.heels_type,
		name: 'Heel type',
		allowedTypes: new Set([QuizQuestionType.SelectManyButtons, QuizQuestionType.SelectManyTags]),
		optionKeys: [
			{ key: 'HEELS_TYPE_LOW', name: 'Low' },
			{ key: 'HEELS_TYPE_MID', name: 'Mid' },
			{ key: 'HEELS_TYPE_HIGH', name: 'High' }
		]
	},
	[QuizQuestionCategory.occupation]: {
		key: QuizQuestionCategory.occupation,
		name: 'Occupation',
		allowedTypes: new Set([QuizQuestionType.FreeText])
	},
};

export const Categories: QuizQuestionCategory[] = Object.values(QuizQuestionCategory);

export const StringToQuizQuestionCategory: {
	[key: string]: QuizQuestionCategory;
} = {};
Object.values(QuizQuestionCategory).forEach((category) => (StringToQuizQuestionCategory[category] = category));
