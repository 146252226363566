import styled from '@emotion/styled';

import { Dropdown } from './form/Dropdown';
import { CategoriesDict, QuizQuestionCategory, StringToQuizQuestionCategory } from './categories';

export const QuestionCategorySelector = (props: {
	questionCategory: QuizQuestionCategory;
	setQuestionCategory: (category: QuizQuestionCategory) => void;
	disabled?: boolean;
}) => {
	const selectedCategory = props.questionCategory;
	const selectedCategoryInfo = CategoriesDict[selectedCategory];

	const mainCategory =
		selectedCategoryInfo.parentCategory != null ? selectedCategoryInfo.parentCategory : props.questionCategory;
	const mainCategoryInfo = CategoriesDict[mainCategory];

	return (
		<Container>
			<Dropdown
				id="question-category"
				label="Category"
				selectedValue={mainCategory}
				setValue={(value: string) => {
					props.setQuestionCategory(StringToQuizQuestionCategory[value]);
				}}
				options={SelectableCategories.map((category) => ({
					value: category,
					name: CategoriesDict[category].name
				}))}
				disabled={props.disabled}
				undefinedOptionText={null}
			/>
			{mainCategoryInfo.subCategories != null && (
				<div style={{ marginTop: '16px', width: '100%' }}>
					<Dropdown
						id="question-sub-category"
						label="Sub category"
						selectedValue={selectedCategory}
						setValue={(value: string | undefined) => {
							if (value === undefined) {
								props.setQuestionCategory(mainCategory);
							} else {
								props.setQuestionCategory(StringToQuizQuestionCategory[value]);
							}
						}}
						options={mainCategoryInfo.subCategories.map((subCategory) => ({
							value: subCategory,
							name: CategoriesDict[subCategory].name
						}))}
						disabled={props.disabled}
						undefinedOptionText="Select one"
					/>
				</div>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
`;

const SelectableCategories = [
	QuizQuestionCategory.custom,
	QuizQuestionCategory.age,
	QuizQuestionCategory.bodyType,
	QuizQuestionCategory.budget,
	QuizQuestionCategory.brands,
	QuizQuestionCategory.clothing_categories,
	QuizQuestionCategory.clothing_preferences,
	QuizQuestionCategory.clothing_size_scale,
	QuizQuestionCategory.colors,
	QuizQuestionCategory.colors_to_avoid,
	QuizQuestionCategory.email,
	QuizQuestionCategory.phone_number,
	QuizQuestionCategory.birthday,
	QuizQuestionCategory.event_type,
	QuizQuestionCategory.fabrics,
	QuizQuestionCategory.fit_bottom,
	QuizQuestionCategory.fit_top,
	QuizQuestionCategory.gender,
	QuizQuestionCategory.goals,
	QuizQuestionCategory.heels,
	QuizQuestionCategory.highlight,
	QuizQuestionCategory.holiday_type,
	QuizQuestionCategory.location,
	QuizQuestionCategory.photo,
	QuizQuestionCategory.shoes_size_scale,
	QuizQuestionCategory.sizes,
	QuizQuestionCategory.style,
	QuizQuestionCategory.work_type,
	QuizQuestionCategory.what_matters,
	QuizQuestionCategory.referral_source,
	QuizQuestionCategory.referral_source_instagram,
	QuizQuestionCategory.referral_source_friend,
	QuizQuestionCategory.referral_source_stylist,
	QuizQuestionCategory.body_issues,
	QuizQuestionCategory.comfort_zone,
	QuizQuestionCategory.jewelry,
	QuizQuestionCategory.style_icons,
	QuizQuestionCategory.instagram,
	QuizQuestionCategory.pinterest,
	QuizQuestionCategory.jeans_type,
	QuizQuestionCategory.heels_type,
	QuizQuestionCategory.necklines_to_avoid,
	QuizQuestionCategory.patterns_to_avoid,
	QuizQuestionCategory.occupation,
];
